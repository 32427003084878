import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

export default function PageNotFound() {
    return (
        <Layout>
            <div className="pnf">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 style={{ textAlign:'center',margin:'100px 0' }}>You've found a page that doesn't exist</h4>
                        </div>  
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
	locales: allLocale {
	  edges {
		node {
		  language
		  ns
		  data
		}
	  }
	}
}
`
